
import {defineComponent} from "vue";

export default defineComponent({
  name: "ActionLogs",
  props: {
    teamId: {}
  },
  setup() {

  }
})
